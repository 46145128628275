<script lang="ts">
	import { Badge } from '$lib/components/ui/badge'
	import { Button } from '$lib/components/ui/button'
	import * as Card from '$lib/components/ui/card'
	import { Image } from '@unpic/svelte'
	import MoveRight from 'lucide-svelte/icons/move-right'

	export let title: string
	export let badge: string
	export let imageUrl: string
	export let imageAlt: string
	export let ingress: string = ''
	export let description: string
	export let buttonHref: string
	export let buttonText: string
</script>

<Card.Root class="bg-blue-200 dark:bg-indigo-900 sm:m-4">
	<div>
		<Card.Header>
			<Card.Title tag="h2">{title}</Card.Title>
			{#if Boolean(ingress)}<Card.Description>{ingress}</Card.Description>{/if}
		</Card.Header>
		<Card.Content>
			<Badge>{badge}</Badge>
		</Card.Content>
		<Card.Content>
			<section class="flex flex-col justify-between sm:flex-row">
				<p class="flex-1">{description}</p>
				<div class="flex flex-1 justify-center">
					<Image
						src={imageUrl}
						layout="constrained"
						width={200}
						height={200}
						alt={imageAlt}
						class="self-center"
						priority
					/>
				</div>
			</section>
		</Card.Content>
	</div>
	<Card.Footer>
		<Button href={buttonHref} class="flex w-full justify-between gap-2">
			{buttonText}
			<MoveRight class="mr-2 h-4 w-4" />
		</Button>
	</Card.Footer>
</Card.Root>
