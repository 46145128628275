<script lang="ts">
	import H1 from './H1.svelte'

	export let title: string
	export let imageUrl: string
	export let imageAlt: string
	export let ingress: string
	export let description: string
	import { Image } from '@unpic/svelte'
</script>

<section
	class="flex flex-col items-center justify-center gap-8 rounded-lg bg-blue-50 bg-blue-950 p-6 sm:m-4 sm:px-16"
>
	<H1 class="text-center">{title}</H1>
	<div class="flex flex-col items-center gap-4 sm:flex-row sm:gap-16">
		<Image src={imageUrl} layout="constrained" width={400} height={400} alt={imageAlt} priority />
		<section class="flex-2 flex flex-col gap-10">
			<p class="text-center text-xl text-gray-700 dark:text-white">
				{ingress}
			</p>

			<p class="text-center text-gray-600 dark:text-white">
				{description}
			</p>
		</section>
	</div>
</section>
